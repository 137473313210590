<template>
  <div>
    <no-access-info-card v-if="showNoAccessInfoCard" />
    <div
      v-else
      class="d-flex flex-md-row mt-2 flex-column-reverse"
    >
      <div class="col-md-9 nopadding">
        <div v-if="jobs">
          <job-card
            v-for="job in jobs.data"
            :key="job.id"
            :job="job"
            :is_own="true"
            :can-show-image="showImages"
            @onImgLoad="loadedImages+= 1"
          />
        </div>
        <div
          v-if="jobs"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-if="jobs.meta.pagination.total > jobs.meta.pagination.per_page"
            v-model="pageNumber"
            :per-page="jobs.meta.pagination.per_page"
            :total-rows="jobs.meta.pagination.total"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <div
          v-if="!jobs || !jobs.data.length"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="@/assets/images/pages/course/empty-list.svg"
            alt="Buypass"
            class="d-inline-block mt-5"
          />
        </div>
      </div>
      <div class="col-md-3 pl-md-1 pr-md-0 p-0">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend
            class="no-border"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input
            v-model="search"
            type="search"
            class="no-border"
            :placeholder="$t('Search...')"
          />
        </b-input-group>
        <filter-card page="myJobs" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BImg,
} from 'bootstrap-vue'
import { debounce } from 'vue-debounce'

import FilterCard from '@/components/course/FilterCard.vue'
import JobCard from '@/components/jobs/JobCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BPagination,
    BImg,

    FilterCard,
    JobCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    imagesCount() {
      let counter = 0
      if (this.jobs) {
        this.jobs.data.forEach(job => {
          counter += this.checkForImage(job)
        })
      }
      return counter
    },
    showImages() {
      return this.loadedImages >= this.imagesCount
    },
    jobs() {
      return this.$store.getters['myJobs/jobs']
    },
    search: {
      get() {
        return this.$store.getters['myJobs/search']
      },
      // eslint-disable-next-line
      set: debounce(function (search) {
        this.$store.commit('myJobs/setSearch', search)
      }, 500),
    },
    pageNumber: {
      set(pageNumber) {
        this.$store.commit('myJobs/setPageNumber', pageNumber)
      },
      get() {
        return this.$store.getters['myJobs/pageNumber']
      },
    },
    query() {
      return this.$store.getters['myJobs/query']
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompany() {
      return 'id' in this.user.active_business_profile
    },
    showNoAccessInfoCard() {
      if (!this.isAuthenticated) return false
      if (this.representACompany) return false
      if (this.user.user_info.approved_hrp !== 2) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    "$route.query.filter": {
      handler() {
        this.$store.dispatch('myJobs/fetchJobs')
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('filterData/fetchCounties')
    if (this.$route.query.filter) {
      this.$store.commit('myJobs/setQuery', JSON.parse(this.$route.query.filter))
    }
    if (!this.showNoAccessInfoCard) this.$store.dispatch('myJobs/fetchJobs')
  },
  beforeDestroy() {
    this.$store.commit('myJobs/clearFilters')
  },
  methods: {
    checkForImage(job) {
      let workplace = null
      if (!job.workplace_situation) return 0
      if (job.attributes.workplace_situation.workplace_situation_group === 'general_practice') {
        workplace = job.attributes.workplace_situation.workplace_situation.clinic
      }
      if (job.attributes.workplace_situation.workplace_situation_group === 'hospital') {
        const keys = Object.entries(job.attributes.workplace_situation.workplace_situation)
        keys.forEach(([key]) => {
          workplace = job.attributes.workplace_situation.workplace_situation[key]
        })
      }
      if (job.attributes.workplace_situation.workplace_situation_group === 'other') {
        workplace = job.attributes.workplace_situation.workplace_situation.custom_workplace
      }
      if (workplace && workplace.image) return 1
      return 0
    },
    updateQuery(query) {
      this.$router.push({
        path: '/my_jobs',
        query: { filter: JSON.stringify(query) },
      })
    },
  },
}
</script>
